/* bootstrap icons */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

/* datatables */
@import url('../node_modules/datatables.net-bs4/css/dataTables.bootstrap4.min.css');
@import url('../node_modules/datatables.net-dt/css/dataTables.dataTables.min.css');
@import url('../node_modules/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css');
@import url('../node_modules/datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css');


.App {
  text-align: center;
}

.homeScreenContainer {
  /* box-sizing: border-box; */
}

/* header */
@media (max-width: 570px) {
  .custom-button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .custom-button-group .btn {
    /* margin-right: 0 !important; */
    margin-bottom: 10px !important;
  }
}

@media (min-width: 570px) {
  .custom-button-group {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
  }

  .custom-button-group .btn {
    /* margin-right: 0 !important; */
    margin-bottom: 10px !important;
  }
}

.myCarousel {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  text-align: var(--bs-body-text-align);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  color: rgb(var(--bs-tertiary-color-rgb));
  box-sizing: border-box;
  position: relative;
  margin-bottom: 4rem;
}

/* Hide scrollbar for browsers that don't support styling */
/* This will hide the scrollbar while still allowing scrolling */
::-webkit-scrollbar {
  /* display: none; */
}

/* For browsers that support styling */
/* Style the scrollbar in both X and Y directions */
::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
  height: 12px;
  /* Height of the scrollbar */
}

/* Track styles */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the scrollbar track */
  border-radius: 10px;
  /* Radius of the scrollbar track */
}

/* Thumb styles (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: #006400;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Radius of the scrollbar thumb */
}

/* Handle hover effect */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar thumb on hover */
}

/* Media query for small screens (up to 768px) */
@media (max-width: 768px) {

  /* Adjust scrollbar width and height for smaller screens */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
}

/* Media query for medium screens (769px to 992px) */
@media (min-width: 769px) and (max-width: 992px) {

  /* Adjust scrollbar width and height for medium screens */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
}

/* Media query for large screens (993px and above) */
@media (min-width: 993px) {

  /* Adjust scrollbar width and height for larger screens */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
}

.back-to-top-button {
  position: fixed;
  bottom: 20px;
  /* Adjust as needed */
  right: 20px;
  /* Adjust as needed */
  background-color: #006400;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 9999;
  /* Ensure the button appears over all elements */
}

.back-to-top-button.visible {
  opacity: 1;
  visibility: visible;
}

.back-to-top-button:focus {
  outline: none;
}

.back-to-top-button:hover {
  background-color: #191b1d;
  /* Darken the color on hover */
}

.fxg-landing-hero {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  --rem: 16;
  --button-height-comfortable: 3.5rem;
  --button-height-condensed: 2.5rem;
  --gray-1: #ccc;
  --gray-900: #111827;
  --gray-800: #1f2937;
  color: #333;
  font-family: FedExSans, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  width: 100%;
  min-height: 350px;
  position: relative;
  margin-top: 0;
}

/*Faq*/
@import url("https://cdnjs.cloudflare.com/ajax/libs/ionicons/4.2.0/css/ionicons.min.css");

.faq-search-wrap {
  padding: 50px 0 60px;
}

.faq-search-wrap .form-group .form-control,
.faq-search-wrap .form-group .dd-handle {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.bg-green-dark-3 {
  background-color: #093b09 !important;
}

@media (min-width: 576px) {
  .mt-sm-60 {
    margin-top: 60px !important;
  }
}

.mt-30 {
  margin-top: 30px !important;
}

.list-group-item.active {
  background-color: #00acf0;
  border-color: #00acf0;
}

.accordion .card .card-header.activestate {
  border-width: 1px;
}

.accordion .card .card-header {
  padding: 0;
  border-width: 0;
}

.card.card-lg .card-header, .card.card-lg .card-footer {
  padding: .9rem 1.5rem;
}

.accordion>.card .card-header {
  margin-bottom: -1px;
}

.card .card-header {
  background: transparent;
  border: none;
}

.accordion.accordion-type-2 .card .card-header>a.collapsed {
  color: #324148;
}

.accordion .card:first-of-type .card-header:first-child>a {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.accordion.accordion-type-2 .card .card-header>a {
  background: transparent;
  color: #00acf0;
  padding-left: 50px;
}

.accordion .card .card-header>a.collapsed {
  color: #324148;
  background: transparent;
}

.accordion .card .card-header>a {
  background: #00acf0;
  color: #fff;
  font-weight: 500;
  padding: .75rem 1.25rem;
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

a {
  text-decoration: none;
  color: #00acf0;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  transition: color 0.2s ease;
}


.badge.badge-pill {
  border-radius: 50px;
}

.badge.badge-light {
  background: #eaecec;
  color: #324148;
}

.badge {
  font-weight: 500;
  border-radius: 4px;
  padding: 5px 7px;
  font-size: 72%;
  letter-spacing: 0.3px;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  text-transform: capitalize;
}

.ml-15 {
  margin-left: 15px !important;
}

.accordion.accordion-type-2 .card .card-header>a.collapsed:after {
  content: "\f158";
}

.accordion.accordion-type-2 .card .card-header>a::after {
  display: inline-block;
  font: normal normal normal 14px/1 'Ionicons';
  speak: none;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: auto;
  position: absolute;
  content: "\f176";
  font-size: 21px;
  top: 15px;
  left: 20px;
}

/* .mr-15 {
  margin-right: 15px !important;
} */

/* phone number input-2 */
.react-tel-input .form-control {
  width: 100% !important;
}

/* checkout image */
@media (max-width: 767px) {
  .checkout-image>img {
    display: none !important;
    margin-top: 0px;
  }
}

/* checkbox */
.form-check-input:checked {
  background-color: var(--bs-success) !important;
  border-color: var(--bs-success) !important;
}

/* my orders */
.MyOrders {
  --dashui-gutter-x: 1.25rem;
  --dashui-gutter-y: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}

/* my order pagination */
.pagination .page-item .page-link {
  color: #198754; /* Bootstrap success color */
}

.pagination .page-item .page-link.active {
  background-color: #198754; /* Bootstrap success background color */
  border-color: #198754; /* Bootstrap success border color */
  color: white;
}

.pagination .page-item .page-link:hover {
  background-color: #759e87; /* Darker shade of success color for hover effect */
  border-color: #145a32;
  color: white;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #145a32;
  border-color: #145a32;
}


/* order details */
.track {
  position: relative;
  background-color: #ddd;
  height: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 60px;
  margin-top: 50px
}

.track .step {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 25%;
  margin-top: -18px;
  text-align: center;
  position: relative
}

.track .step.active:before {
  background: #068671
}

.track .step::before {
  height: 7px;
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  top: 18px
}

.track .step.active .icon {
  background: #068671;
  color: #fff
}

.track .icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: relative;
  border-radius: 100%;
  background: #ddd
}

.track .step.active .text {
  font-weight: 400;
  color: #000
}

.track .text {
  display: block;
  margin-top: 7px
}

/* disable bootstrap dashboard styling */
.offcanvas-end {
  width: auto !important;
  transform: none !important;
}

/* swal button color */
.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #006400 !important;
  font-size: 12px;
  border: 1px solid #006400 !important;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

.swal2-confirm{
  background-color: #006400 !important;
}

.swal2-container .swal2-styled.swal2-confirm {
  background-color: #006400 !important;
}

/* faq page */
.blinking-icon::before {
  content: '⚡'; /* Change this to the icon you want to blink */
  animation: blink 1s infinite;
  margin-right: 8px;
}

@keyframes blink {
  0%, 50%, 100% { opacity: 1; }
  25%, 75% { opacity: 0; }
}

/* service news page */
.blinking-icon-service-news::before {
  content: '⚡'; /* Change this to the icon you want to blink */
  animation: blink 1s infinite;
  margin-right: 8px;
}

@keyframes blink {
  0%, 50%, 100% { opacity: 1; }
  25%, 75% { opacity: 0; }
}


/* about us page */
.center-loader {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* height: 100vh; Adjust this height as needed */
}


/* review order */
.rating-stars {
  display: flex;
  font-size: 24px;
}

.star {
  cursor: pointer;
  color: #ccc;
}

.star.filled {
  color: #f8b739; /* Color for filled star */
}



/* Add this to your CSS file */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Dark background with opacity */
  backdrop-filter: blur(5px); /* Background blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears above other content */
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#datatableWarehouse{
  width: 100% !important
}

/* flutterwave button */
.flutterwave-button button {
  background-color: green;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

/* Toast text font size */
.swal2-popup.swal2-toast .swal2-title {
  margin: .5em 1em;
  padding: 0;
  font-size: 12px !important;
  text-align: initial;
}

.payment-checkmark {
  width: 100px;
  margin: 0 auto;
  padding: 20px 0;
}
.payment-checkmark .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  -webkit-animation: dash 2s ease-in-out;
          animation: dash 2s ease-in-out;
}
.payment-checkmark .spin {
  -webkit-animation: spin 2s;
          animation: spin 2s;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}